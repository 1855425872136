import React, { lazy, Suspense, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { getGridMenu } from "redux/actions/Menu";
import { useDispatch, useSelector } from "react-redux";

export const AppViews = () => {
  const dispatch = useDispatch();
  const { menu } = useSelector((state) => state.menu);

  useEffect(() => {
    if (!menu.length) {
      dispatch(getGridMenu({}));
    }
  }, [dispatch, menu]);

  // return (
  //   <div className="app-views">
  //     {menu.length === 0 ? (
  //       <Loading cover="content" />
  //     ) : (
  //       <Suspense fallback={<Loading cover="content" />}>
  //         <Switch>
  //           {menu.map((menuItem) => {
  //             let component = menuItem.name.split(" ").join("");
  //             return menuItem.submenu.length ? (
  //               menuItem.submenu.map((submenuItem) => (
  //                 <Route
  //                   path={submenuItem.path}
  //                   component={lazy(() =>
  //                     import(
  //                       `./${menuItem.name}/${submenuItem.name
  //                         .split(" ")
  //                         .join("")}`
  //                     )
  //                   )}
  //                   key={`sub-${submenuItem.key}`}
  //                 />
  //               ))
  //             ) : (
  //               <Route
  //                 path={menuItem.path}
  //                 component={lazy(() => import(`./${component}`))}
  //                 key={menuItem.key}
  //               />
  //             );
  //           })}
  //           <Route
  //             exact
  //             path={`${APP_PREFIX_PATH}`}
  //             component={lazy(() => import(`./home`))}
  //           />
  //           <Route exact path={"/"} component={lazy(() => import(`./home`))} />
  //           <Route
  //             path={`${APP_PREFIX_PATH}/page-not-found`}
  //             component={lazy(() => import(`./page404`))}
  //           />
  //           <Route path="*">
  //             <Redirect to="/app/page-not-found" />
  //           </Route>
  //         </Switch>
  //       </Suspense>
  //     )}
  //   </div>
  // );
  return (
    <div className="app-views">
      {menu.length === 0 ? (
        <Loading cover="content" />
      ) : (
        <Suspense fallback={<Loading cover="content" />}>
          <Switch>
          <Route
              exact
              path={`${APP_PREFIX_PATH}/bill-payments`}
              component={lazy(() => import(`./Transfers/BillPayments`))}
            />
            {menu.map((menuItem) => {
              let com = menuItem.name.split(" ").join("");
              const component = lazy(() => import(`./${com}`));
              return (
                <Route
                  exact
                  path={menuItem.path}
                  component={component}
                  key={menuItem.key}
                />
              );
            })}
            <Route
              exact
              path={`${APP_PREFIX_PATH}`}
              component={lazy(() => import(`./Dashboard`))}
            />
            <Route
              exact
              path={`${APP_PREFIX_PATH}/management/:path/:accountId`}
              component={lazy(() => import(`./AcquirerView`))}
            />
            <Route
              exact
              path={`${APP_PREFIX_PATH}/transfers/topups`}
              component={lazy(() => import(`./Transfers/Topups`))}
            />
             <Route
              exact
              path={`${APP_PREFIX_PATH}/topups`}
              component={lazy(() => import(`./Transfers/Topups`))}
            />
              <Route
              exact
              path={`${APP_PREFIX_PATH}/bill-payments/bulk/:batchId`}
              component={lazy(() => import(`./Transfers/BillPayments/BillComponents/BillBulkBatchDetailsView`))}
            />
             <Route
              exact
              path={`${APP_PREFIX_PATH}/transfers`}
              component={lazy(() => import(`./Transfers`))}
            />
             <Route
              exact
              path={`${APP_PREFIX_PATH}/history/reports`}
              component={lazy(() => import(`./Reports`))}
            />
             <Route
              exact
              path={`${APP_PREFIX_PATH}/history`}
              component={lazy(() => import(`./History`))}
            />
            <Route
              exact
              path={`${APP_PREFIX_PATH}/history/:1`}
              component={lazy(() => import(`./History`))}
            />
            <Route
              exact
              path={`${APP_PREFIX_PATH}/history/:2`}
              component={lazy(() => import(`./History`))}
            />
             <Route
              exact
              path={`${APP_PREFIX_PATH}/history/settlements`}
              component={lazy(() => import(`./History`))}
            />
             <Route
              exact
              path={`${APP_PREFIX_PATH}/business-profile`}
              component={lazy(() => import(`./Settings/Components/UserProfile`))}
            />
            <Route
              exact
              path={`${APP_PREFIX_PATH}/user-management`}
              component={lazy(() => import(`./UserManagement`))}
            />
            <Route
              exact
              path={`${APP_PREFIX_PATH}/branch-management`}
              component={lazy(() => import(`./BranchManagement`))}
            />
            <Route
              exact
              path={`${APP_PREFIX_PATH}/applications`}
              component={lazy(() => import(`./Settings/Components/ApplicationMain`))}
            />
             <Route
              exact
              path={`${APP_PREFIX_PATH}/customer-management`}
              component={lazy(() => import(`./Customers`))}
            />
             <Route
              exact
              path={`${APP_PREFIX_PATH}/circles`}
              component={lazy(() => import(`./Circles`))}
            />
             <Route
              exact
              path={`${APP_PREFIX_PATH}/bill-management`}
              component={lazy(() => import(`./Bills`))}
            />
             <Route
              exact
              path={`${APP_PREFIX_PATH}/collections`}
              component={lazy(() => import(`./Settings/Components/Collections`))}
            />
            <Route
              exact
              path={`${APP_PREFIX_PATH}/transfers/topups/new`}
              component={lazy(() => import(`./Transfers/Topups/NewTopup/NewTopup`))}
            />
            <Route
              exact
              path={`${APP_PREFIX_PATH}/transfers/single`}
              component={lazy(() => import(`./Transfers/Single`))}
            />
             <Route
              exact
              path={`${APP_PREFIX_PATH}/bill-payments/single`}
              component={lazy(() => import(`./Transfers/BillPayments/BillComponents/SingleBillPayment`))}
            />
             <Route
              exact
              path={`${APP_PREFIX_PATH}/bill-payments/single/:batchId`}
              component={lazy(() => import(`./Transfers/BillPayments/BillComponents/SingleBillPayment/SingleBatchDetailsView`))}
            />
            <Route
              exact
              path={`${APP_PREFIX_PATH}/transfers/single/:batchId`}
              component={lazy(() => import(`./Transfers/Single/SingleBatchDetailsView`))}
            />
            <Route
              exact
              path={`${APP_PREFIX_PATH}/transfers/bulk`}
              component={lazy(() => import(`./Transfers/Bulk`))}
            />
             <Route
              exact
              path={`${APP_PREFIX_PATH}/beneficiary-management/bulk`}
              component={lazy(() => import(`./BeneficiaryManagement/components/Bulk`))}
            />
            <Route
              exact
              path={`${APP_PREFIX_PATH}/transfers/bulk/:batchId`}
              component={lazy(() => import(`./Transfers/Bulk/BulkBatchDetailsView`))}
            />
            <Route
              exact
              path={`${APP_PREFIX_PATH}/accounts/transfers/list/:type`}
              component={lazy(() => import(`./Accounts`))}
            />
            <Route
              exact
              path={`${APP_PREFIX_PATH}/accounts/transfers/new`}
              component={lazy(() => import(`./Transfers`))}
            />
             <Route
              exact
              path={`${APP_PREFIX_PATH}/pos`}
              component={lazy(() => import(`./PointOfSale`))}
            />
            <Route exact path={"/"} component={lazy(() => import(`./home`))} />
            <Route
              path={`${APP_PREFIX_PATH}/page-not-found`}
              component={lazy(() => import(`./page404`))}
            />
            
            <Route path="*">
              <Redirect to="/app/page-not-found" />
            </Route>
          </Switch>
        </Suspense>
      )}
    </div>
  );
};

export default React.memo(AppViews);
